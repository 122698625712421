import {useEffect, useState, useRef} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {Toast, ToastContainer} from 'react-bootstrap'
import {verifyotpApi, fetchUserPermissionsApi} from '../core/_requests'
import { setMessage } from '../../../../store/alertMessage/actions'
import { useDispatch } from 'react-redux'
import { setUserPermissions } from 'src/store/permission/actions'
const initialValues = {
  token: '',
}

const verifyOTPSchema = Yup.object().shape({
  token: Yup.string()
    .min(6, 'OTP must be 6 digits')
    .max(6, 'OTP must be 6 digits')
    .required('OTP is required'),
})

export function VerifyOTP() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {state} = useLocation() as any
  const dispatch = useDispatch()
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({type: '', message: '', show: false})
      }, 4500)
    }
  }, [sessionMessage.message])

  useEffect(() => {
    if (!state?.token || state?.token == '' || state?.user == '') {
      navigate('/auth/login')
    }
  }, [])
  const inputRef = useRef<any>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current?.focus();
    }
  }, []);
 

  const formik = useFormik({
    initialValues,
    validationSchema: verifyOTPSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let userData = JSON.parse(state.user) ?? {}
        console.log(userData, 'user data90909')
        // let userData = localStorage.getItem("objUser") as any
        // userData = JSON.parse(userData)
        await verifyotpApi({user_id: userData?.id ?? '', token: values.token})
          .then((data: any) => {
            if (data?.responseStatus == 200) {
              localStorage.setItem('authtoken', state?.token ?? '')
              localStorage.setItem('objUser', state?.user ?? '')
              setLoading(false)
                           dispatch(setMessage('success', `Login Successful!`))
                           

              setTimeout(() => {
                window.location.replace('/partyledger/list-partyledger')
              }, 1500)
              
            } else {
              dispatch(setMessage('error', 'The entered OTP is not valid. Please try again.'))
              setLoading(false)
            }
          })
          .catch((err: any) => {
            if (err?.response?.status == 429) {
              dispatch(setMessage('error', 'The entered OTP is not valid. Please try again.'))
            } else {
              dispatch(setMessage('error', 'The entered OTP is not valid. Please try again.'))
            }
            setSubmitting(false)
            setLoading(false)
          })
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Open the two-step verfication app on your mobile device to get your verfication code.
        </div>
        {/* end::Link */}
      </div>

      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
        <input
          type='text'
          placeholder='Enter OTP'
          autoComplete='off'
         ref={inputRef}
          {...formik.getFieldProps('token')}
          onChange={(e) => {
            e.target.value = e.target.value.trim(); // Trim the input value
            formik.handleChange(e); // Update the Formik state
          }}  
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.token && formik.errors.token},
            {
              'is-valid': formik.touched.token && !formik.errors.token,
            }
          )}
        />
        {formik.touched.token && formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.token}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={loading}
        >
          {loading ? 'Please Wait...' : 'Verify OTP'}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            onClick={() => {
              navigate('/auth')
            }}
            disabled={loading}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      <ToastContainer className='p-3' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg={sessionMessage.type}
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* end::Form group */}
    </form>
  )
}
