import { useEffect, useRef } from 'react'
import { AuditEditModalHeader } from './AuditEditModalHeader'
import { AuditEditModalFormWrapper } from './AuditEditModalFormWrapper'
import { useListView } from '../core/ListViewProvider'

const AuditEditModal = () => {
  const modalRef = useRef<any>()
  const { setItemIdForUpdate } = useListView()

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (modalRef.current && !modalRef.current.contains(e.target) && e.target.tagName !== 'HTML') {
        setItemIdForUpdate(undefined)
      }
    }

    // document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      // document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  })
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // Check if the clicked element is not within the modal and it's not a scroll event
      if (
        modalRef.current 
        && !modalRef.current.contains(e.target) 
        && e.target.tagName !== 'HTML' // Exclude the whole page click
      ) {
        setItemIdForUpdate(undefined)
      }
    }
  
    // document.addEventListener('mousedown', checkIfClickedOutside)
  
    return () => {
      // Cleanup the event listener
      // document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_Audit'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
        data-backdrop="static"
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog mw-650px' ref={modalRef}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <AuditEditModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body mt-5 scroll-y p-0 px-10'>

              <AuditEditModalFormWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { AuditEditModal }
