/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
// import {
//   PaginationState,
//   QUERIES,
//   WithChildren,
//   createResponseContext,
//   initialQueryResponse,
//   initialQueryState,
//   stringifyRequestQuery,
// } from '../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {Audit} from './_models'
import {getAudits} from './_requests'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from 'src/_metronic/helpers'
import {useSelector} from 'react-redux'

const QueryResponseContext = createResponseContext<Audit>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const cityList = useSelector((state: any) => state.commonList.cityList)
  

  const isAdmin = cityList?.roleInfo?.id === 1 || cityList?.roleInfo?.id === 2

  const isAuditLogPermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.audit_logs === true
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.SERVICECENTERS_LIST}-${query}`,
    () => {
      return getAudits(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse() as any
  if (!response || !response?.responseData) {
    return []
  }

  return response?.responseData || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse() as any
  if (!response || !response?.payload || !response?.payload?.pagination) {
    return defaultPaginationState
  }

  return response?.payload?.pagination || []
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
}
