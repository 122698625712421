import {useEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import Select from 'react-select'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {KTSVG} from 'src/_metronic/helpers'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import {getUsers} from '../../core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const AuditsListFilter = () => {
  const {state, updateState} = useQueryRequest()
  const isLoading = false
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [auditList, setAuditList] = useState([])

  const dropdownRef = useRef<any>(null)

  const [fromDate, setFromDate] = useState<any>(moment().format('DD-MM-YYYY'))
  const [toDate, setToDate] = useState<any>(moment().format('DD-MM-YYYY'))

  const [reqData, setReqData] = useState<any>({
    company_name: '',
    transaction_status: '',
    city_id: '',
    state_id: '',
    contact_number: '',
    email: '',
    out_country_pincode: '',
    distance_for_out_country: '',
  })

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setFromDate(moment().format('DD-MM-YYYY'))
    setToDate(moment().format('DD-MM-YYYY'))
    setReqData({
      company_name: '',
      city_id: '',
      state_id: '',
      contact_number: '',
      email: '',
      out_country_pincode: '',
      distance_for_out_country: '',
      transaction_status: '',
    })
    updateState({
      filter: {fromDate : moment().format('DD-MM-YYYY'), toDate: moment().format('DD-MM-YYYY')},
      page: 1,
      perPage: state.perPage,
    })
  }

  const filterData = () => {
    updateState({
      filter: {...reqData, fromDate, toDate},
      page: 1,
      perPage: state.perPage,
    })
  }

  const fetchAuditList = async () => {
    try {
      let {responseStatus, responseData} = (await getUsers('')) as any
      if (responseStatus === 200 && responseData && responseData.length) {
        const mappedCustomers = responseData.map((x: any) => {
          return {
            label: x.name,
            value: x.id,
            ...x,
          }
        })
        setAuditList(mappedCustomers)
        return mappedCustomers
      } else {
        setAuditList([])
        return []
      }
    } catch (err: any) {
      console.error('Something went wrong:', err?.message || 'Unknown error')
      return []
    }
  }

  useEffect(() => {
    fetchAuditList()
  }, [])

  useEffect(() => {
    updateState({
      filter: {fromDate, toDate},
      page: 1,
      perPage: state.perPage,
    })
  }, [])

  const handleDateChange = (date: any) => {
    if (date) {
      setFromDate(moment(date).format('DD-MM-YYYY'))
    } else {
      setFromDate(null)
    }
  }

  const handleToDateChange = (date: any) => {
    if (date) {
      setToDate(moment(date).format('DD-MM-YYYY'))
    } else {
      setToDate(null)
    }
  }

  const handleDropdownClick = (event: any) => {
    event.stopPropagation()
  }

  const handleClickOutside = (event: any) => {
    if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
      // setIsModelOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        onClick={() => {
          setIsModelOpen(!isModelOpen)
        }}
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      <div
        ref={dropdownRef}
        onClick={handleDropdownClick}
        className={`menu menu-sub menu-sub-dropdown mw-600px w-md-700px
        position-absolute
        ${isModelOpen ? 'd-block' : 'd-none'}`}
        data-kt-menu='true'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark d-flex align-items-center justify-content-between fw-bolder'>
            Filter Options
            <div>
              <button
                type='button'
                disabled={isLoading}
                className='btn btn-light btn-active-light-primary fw-bold px-6 '
                onClick={() => {
                  resetData()
                  setIsModelOpen(false)
                }}
              >
                <i className='fa fa-times'></i>
              </button>
            </div>
          </div>
        </div>
        <div className='separator border-gray-200'></div>
        <div
          className='px-7 scroll-y py-5'
          data-kt-servicecenter-table-filter='form'
          style={{maxHeight: '400px'}}
        >
          <div
            className='d-flex flex-column  mb-5 me-n7 pe-4'
            id='kt_modal_add_servicecenter_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_servicecenter_header'
            data-kt-scroll-wrappers='#kt_modal_add_servicecenter_scroll'
            data-kt-scroll-offset='300px'
          >
            <Row className='mb-8'>
              <Col lg={6}>
                <label className='fw-bold fs-6 mb-2'>Created at</label>
                <div className=''>
                  <span className='text-start text-black fw-bold fs-6 me-2'>From</span>
                  <DatePicker
                    selected={fromDate ? moment(fromDate, 'DD-MM-YYYY').toDate() : null}
                    onChange={handleDateChange}
                    placeholderText='DD-MM-YYYY'
                    dateFormat='dd-MM-yyyy'
                    className='form-control form-control-solid'
                    maxDate={new Date()}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <label className=' fw-bold fs-6 mb-2'> &nbsp; </label>
                <div className=''>
                  <span className='text-start text-black fw-bold fs-6 me-2'>To</span>
                  <DatePicker
                    selected={toDate ? moment(toDate, 'DD-MM-YYYY').toDate() : null}
                    onChange={handleToDateChange}
                    placeholderText='DD-MM-YYYY'
                    dateFormat='dd-MM-yyyy'
                    className='form-control form-control-solid '
                    minDate={fromDate ? moment(fromDate, 'DD-MM-YYYY').toDate() : null}
                    maxDate={new Date()}
                  />
                </div>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col xl={12}>
                <label className='fw-bold fs-6 mb-2'>Modified By</label>
                <Select
                  options={auditList}
                  className='custom_select '
                  classNamePrefix='Select'
                  placeholder='Select User'
                  onChange={(e: any) => {
                    setReqData({...reqData, transaction_status: e?.value})
                  }}
                  value={
                    reqData?.transaction_status
                      ? auditList.find(
                          (option: any) => option?.value === reqData?.transaction_status
                        )
                      : []
                  }
                />
              </Col>
            </Row>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              disabled={isLoading}
              type='button'
              className='btn sbmt-btn fw-bold px-6 me-3'
              data-kt-menu-dismiss='true'
              data-kt-servicecenter-table-filter='filter'
              onClick={() => {
                setIsModelOpen(false)
                filterData()
              }}
            >
              Apply
            </button>
            <button
              type='button'
              disabled={isLoading}
              className='btn btn-light btn-active-light-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-servicecenter-table-filter='reset'
              onClick={() => {
                resetData()
                setIsModelOpen(false)
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {AuditsListFilter}
