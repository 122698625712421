import { authAction } from './types'
const isLoggedIn = localStorage.getItem('isLoggedIn')
const token = localStorage.getItem('token')
const data = localStorage.getItem('data')
  ? JSON.parse(localStorage.getItem('data'))
  : {}

const initialState = {
  isLoggedIn: isLoggedIn == 'true' ? isLoggedIn : false,
  token: token ? token : null,
  data: data,
}

export const authDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case authAction.SET_AUTH:
      localStorage.setItem('token', payload.token)
      localStorage.setItem('isLoggedIn', payload.isLoggedIn)
      localStorage.setItem('data', JSON.stringify(payload?.data))
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        token: payload.token,
        data: payload.data,
      }
    case authAction.REMOVE_AUTH:
      localStorage.clear()
      return { ...state, isLoggedIn: false, token: null }
    default:
      return state
  }
}
