import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_NEW = process.env.REACT_APP_API_URL


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const RESENDOTP_SELLER_URL = `${API_URL_NEW}/resend-otp`
export const VERIFYOTP_SELLER_URL = `${API_URL_NEW}/otp-verify`
export const LOGOUT_URL = `${API_URL_NEW}/auth/logout`
export const USER_URL = `${API_URL}/users`
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function resendOTP(whatsapp_number: string) {
  return axios.post<AuthModel>(RESENDOTP_SELLER_URL, {
    whatsapp_number,
  })
}

export function verifyOTP(token: string, otp: string) {
  return axios.post<AuthModel>(VERIFYOTP_SELLER_URL, {
    token,
    otp,
  })
}

export function loginNew(user_name: string, password: string) {
  return axios.post<any>(`${API_URL_NEW}/auth/login`, {
    user_name,
    password,
  })
}

export function forgotPasswordApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/auth/forgot-password`, data).then((data: any) => data.data)
}

export function changePasswordApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/auth/change-password`, data).then((data: any) => data.data)
}

export function resetPasswordApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/auth/reset-password`, data).then((data: any) => data.data)
}

export function verifyotpApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/auth/check_2fa_code`, data).then((data: any) => data.data)
}

export function generate2faOTPApi(data: any) {
  return axios
    .post<any>(`${API_URL_NEW}/auth/generate_2fa_otp`, data)
    .then((data: any) => data.data)
}

export function setup2faApi(data: any) {
  return axios
    .post<any>(`${API_URL_NEW}/auth/verify_to_enable_2fa`, data)
    .then((data: any) => data.data)
}

export function disable2faApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/auth/disable_2fa`, data).then((data: any) => data.data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function logoutApi() {
  return axios.post(LOGOUT_URL).then((response) => response.data)
}
export function fetchUserPermissionsApi(id: any) {
  return axios.get(`${USER_URL}/${id}`).then((response: any) => response.data)
}
