import { commonListAction } from "./types"

const initialState = {
    branchList: [],
    cityList: {},
    redirectList: '',
}

export const commonListReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case commonListAction.SET_DROPDOWN_BRANCH_LIST:
            return { ...state, branchList: payload }
        case commonListAction.SET_CITY_LIST:
            return { ...state, cityList: payload }

        case commonListAction.REDIRECT_PARTY_LIST:
            return { ...state, redirectList: payload }

        default: return state;
    }
}

