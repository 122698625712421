import {useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
// import {KTCardBody} from '../../../../../_metronic/helpers'
import {AuditsListLoading} from '../components/loading/AuditsListLoading'
import {AuditsListPagination} from '../components/pagination/AuditsListPagination'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {Audit} from '../core/_models'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {AuditsColumns} from './columns/_columns'
import {KTCardBody} from 'src/_metronic/helpers'
import moment from 'moment'
import {checkDisplayValue, formatDate} from 'src/utils'
import {constants} from 'src/utils/const'
import {fontWeight} from 'html2canvas/dist/types/css/property-descriptors/font-weight'
// const test = [{
//   sr_no:123,
//   audit_code: 38521,
//   audit_name: 'Irfan',
//   city_name:'Ahmedabad',
//   audit_current_balance: 500,
//   monday_final: '15/06/2024',
//   two_steps: 'Active'

// }]
const AuditsTable = () => {
  const Audits = useQueryResponseData()

  const isLoading = useQueryResponseLoading()

  const data = useMemo(() => Audits, [Audits])
  const columns = useMemo(() => AuditsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_Audits'
          className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            {/* <tr className='text-start text-gray-700 fw-bolder fs-6 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Audit>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr> */}
            <tr className='text-start text-gray-700 fw-bolder fs-6 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return (
                  <CustomRow row={row}  style={{fontWeight: 'bold',}} key={`row-${i}-${row.id}`} />
                )
              })
            ) : (
              // (<tr className="border-bottom">
              //                 <td className="p-5 ">
              //                     <h4 className="fw-normal fs-5"> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h4>
              //                     <p className="fs-7 text-left text-muted"> Date: 15-05-024, 15:30 PM </p>
              //                 </td>
              //                 <td className="p-5 fs-4 text-center">Pawan Garg</td>
              //             </tr>

              <tr>
                <td colSpan={headers.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AuditsListPagination />
      {isLoading && <AuditsListLoading />}
    </KTCardBody>
  )
}

export {AuditsTable}
