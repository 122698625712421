import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import {Provider} from 'react-redux'
import './_metronic/assets/sass/custom.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {AppRoutes} from './app/routing/AppRoutes'
import store from './store/store.js'

// axios.interceptors.request.use(function (config: any) {
//   config.headers.Authorization = localStorage.getItem('token') as any
//   return config
// })

// axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') as any
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      setTimeout(() => {
        window.location.reload()
        window.location.href = '/login'
      }, 1000)
      return Promise.reject({message: 'Your login session expired'})
    } else if (error.response.status === 500) {
      setTimeout(() => {
        // window.location.reload()
      }, 1000)
      return Promise.reject({message: 'Please reload page'})
    }
    return Promise.reject(error)
  }
)

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </Provider>
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
