/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState,useRef} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, loginNew} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
// import {useAlert} from 'react-alert'
import Toast from 'react-bootstrap/Toast'
import {ToastContainer} from 'react-bootstrap'
import Setup2FA from './LoginSetup2fa'
import { useDispatch } from 'react-redux'
import { setMessage } from 'src/store/alertMessage/actions'

const loginSchema = Yup.object().shape({
  user_name: Yup.string().required('User name is required'),
  password: Yup.string()
  .min(7, 'Password must be at least 7 characters')
    .required('Password is required'),
})

const initialValues = {
  user_name: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [show2FA, setShow2FA] = useState(false)
  const [authToken, setAuthToken] = useState<any>('')
  const [objUser, setObjUser] = useState<any>({})
  const dispatch = useDispatch()
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const inputRef = useRef<any>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current?.focus();
    }
  }, []);
  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({type: '', message: '', show: false})
      }, 4500)
    }
  }, [sessionMessage.message])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
      
        const dataNew: any = await loginNew(values?.user_name, values?.password)
          .then((res: any) => {
            if (res?.data?.responseStatus == 200) {
                    if (res?.data?.responseData?.user?.is_2fa_enable == 1) {
                navigate('/auth/verifyotp', {
                  state: {
                    token: res?.data?.responseData?.token ?? '',
                    user: JSON.stringify(res?.data?.responseData?.user) ?? '',
                  },
                })
              } else if (res?.data?.responseData?.user?.role_id == 1 || res?.data?.responseData?.user?.role_id == 2 || res?.data?.responseData?.user?.role_id == 3) {
                setShow2FA(true)

                setLoading(false)
                setAuthToken(res?.data?.responseData?.token ?? '')
                setObjUser(res?.data?.responseData?.user ?? {})
              } else {
                console.log(res?.data?.responseData?.user)

                localStorage.setItem('authtoken', res?.data?.responseData?.token)
                localStorage.setItem('objUser', JSON.stringify(res?.data?.responseData?.user))
                window.location.replace('/partyledger/list-partyledger')
              }
            } else {
         
              dispatch(setMessage('error', 'Invalid login credentials'))
              setLoading(false)
            }
          })
          .catch((err: any) => {
         
            console.log('Invalid ())(**wqs***')
            if (err?.response?.status == 429) {
              dispatch(setMessage('error', 'Invalid login credentials'))
              
            } else {
              dispatch(setMessage('error', err?.response?.data?.responseMessage ))       }
            setSubmitting(false)
            setLoading(false)
          })
      } catch (error:any) {
        
        dispatch(setMessage('error', error?.response?.data?.responseMessage ))
        
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  
  const handleUppercaseInput = (e:any) => {
    e.target.value = e.target.value.trimStart().toUpperCase();
    formik.handleChange(e);
  };
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}

      <div className='text-center mb-11'>
        <h1 className='text-dark fs-1 fw-bolder mb-3'>Login</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>User Name</label>
        <input
          placeholder='Enter User Name'
          ref={inputRef}
          onChange={handleUppercaseInput}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.user_name && formik.errors.user_name},
            {
              'is-valid': formik.touched.user_name && !formik.errors.user_name,
            }
          )}
          type='text'
          name='user_name'
          autoComplete='off'
        />
        {formik.touched.user_name && formik.errors.user_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.user_name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          
          {...formik.getFieldProps('password')}
          onChange={(e) => {
            e.target.value = e.target.value.trim(); // Trim the input value
            formik.handleChange(e); // Update the Formik state
          }}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={loading || formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <Setup2FA show2FA={show2FA} setShow2FA={setShow2FA} token={authToken} userObj={objUser} />
      <ToastContainer className='p-3' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg='danger'
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </form>
  )
}
