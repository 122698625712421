// @ts-nocheck
import {Column} from 'react-table'
import {checkDisplayValue, formatDate} from '../../../../../../../utils'
import {constants} from '../../../../../../../utils/const'
import {Audit} from '../../core/_models'
import {AuditActionsCell} from './AuditActionsCell'
import {AuditCustomHeader} from './AuditCustomHeader'
import {AuditSelectionCell} from './AuditSelectionCell'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {AuditSelectionHeader} from './AuditSelectionHeader'
// import translations from '../../../../../../translations/en.json';
import {AuditTwoStepsCell} from './AuditTwoStepsCell'
import Badge from 'react-bootstrap/Badge'
const AuditsColumns: ReadonlyArray<Column<Audit>> = [
  // {
  //   Header: (props) => <AuditSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => <AuditSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => (
      <AuditCustomHeader tableProps={props} title='DATE/TIME' className='min-w-70px' />
    ),
    accessor: 'created_at',
    // Cell: ({ value }) => checkDisplayValue(value),
    Cell: ({value}) => formatDate(value, constants.DATE_FORMAT.DD_MM_YYYY_HH_MM_A),
  },
  {
    Header: (props) => (
      <AuditCustomHeader tableProps={props} title='AUDIT DETAILS' className='min-w-125px' />
    ),
    accessor: 'name',
    Cell: ({value}) => (
      <OverlayTrigger
        key='top'
        placement='top'
        overlay={<Tooltip id={`tooltip-top`}>{checkDisplayValue(value)}</Tooltip>}
      >
        <div className='remarks-cell'>
          {checkDisplayValue(value) && checkDisplayValue(value).length > 30
            ? checkDisplayValue(value).substring(0, 30) + '...'
            : checkDisplayValue(value)}{' '}
        </div>
      </OverlayTrigger>
    ),
  },
  {
    Header: (props) => (
      <AuditCustomHeader tableProps={props} title='MODIFIED BY' className='min-w-125px' />
    ),
    accessor: 'modified_by',
    Cell: ({...props}) => checkDisplayValue(props?.data[props?.row?.index]?.usersInfo?.name),
  },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title="Last Monday Balance" className='min-w-120px' />
  //   ),
  //   accessor: 'monday_finals_amount',

  //   Cell: ({ value }) => checkDisplayValue(value),
  // },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title="Last Monday Final Date" className='min-w-120px' />
  //   ),
  //   accessor: 'monday_finals',

  //   Cell: ({ value }) => formatDate(value, constants.DATE_FORMAT.DD_MM_YYYY_HH_MM_A),
  // },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title='Created By' className='min-w-125px' />
  //   ),
  //   accessor: 'created_by',
  //   Cell: ({ value }) => 'Aiyub Panara',
  //   // Cell: ({ value }) => checkDisplayValue(parseFloat(value).toLocaleString()),
  // },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title='Updated By' className='min-w-125px' />
  //   ),
  //   accessor: 'updated_by',
  //   Cell: ({ value }) => 'Irfan',
  //   // Cell: ({ value }) => checkDisplayValue(parseFloat(value).toLocaleString()),
  // },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title='Created At' className='min-w-125px' />
  //   ),
  //   accessor: 'created',
  //   Cell: ({ value }) => '15-04-2024',
  //   // Cell: ({ value }) => checkDisplayValue(parseFloat(value).toLocaleString()),
  // },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title='Updated At' className='min-w-125px' />
  //   ),
  //   accessor: 'updated',
  //   Cell: ({ value }) => '18-04-2024',
  //   // Cell: ({ value }) => checkDisplayValue(parseFloat(value).toLocaleString()),
  // },

  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title="Status" className='min-w-120px' />
  //   ),
  //   accessor: 'is_active',

  //   Cell: ({ value }) => <Badge bg={value == "1" ? "success" : "danger"} className='fs-6' >{value == '1' ? "Active" : "In Active"}</Badge>,
  // },
  // {
  //   Header: (props) => (
  //     <AuditCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({ ...props }) => (
  //     <AuditActionsCell id={props.data[props.row.index].uuid} data={props.data[props.row.index]} />
  //   ),
  // },
]

export {AuditsColumns}
