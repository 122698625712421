import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {configuration} from '../../../../../configuration'
const API_URL = configuration.apiUrl
const Customer_URL = `${API_URL}/parties`
const City_URL = `${API_URL}/cities`
const PARTY_URL = `${API_URL}/parties/onlyPartys`
const USER_URL = `${API_URL}/users/allUser`
const getCustomers = (query: string): Promise<any> => {
  return axios.get(`${Customer_URL}?${query}`).then((d: AxiosResponse<any>) => d.data)
}

const getParty = (query: string): Promise<any> => {
  return axios.get(`${PARTY_URL}?is_active=true&sort=party_name&order=ASC`).then((d: AxiosResponse<any>) => d.data)
}

const getUsers = (query: string): Promise<any> => {
  return axios.get(`${USER_URL}?sort=name&order=ASC`).then((d: AxiosResponse<any>) => d.data)
}
const uniqueCode = (query: string): Promise<any> => {
  return axios
    .get(`${Customer_URL}/uniqueCode?party_code=${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}
const getCities = (): Promise<any> => {
  return axios.get(`${City_URL}?sort=name&order=ASC`).then((d: AxiosResponse<any>) => d.data)
}
const getCustomerById = (id: any): Promise<any> => {
  return axios
    .get(`${Customer_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const createCustomer = (data: any): Promise<any> => {
  return axios
    .post(Customer_URL, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const createCity = (data: any): Promise<any> => {
  return axios.post(City_URL, data).then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateCustomer = (data: any, id: any): Promise<any> => {
  return axios
    .patch(`${Customer_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteCustomer = (id: any): Promise<any> => {
  return axios
    .delete(`${Customer_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const DeviceTokenReset = (id: any): Promise<any> => {
  return axios
    .delete(`${Customer_URL}/deviceTokenReset/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteSelectedCustomers = (CustomerIds: Array<ID>): Promise<void> => {
  const requests = CustomerIds.map((id) => axios.delete(`${Customer_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  createCustomer,
  deleteCustomer,
  deleteSelectedCustomers,
  getCustomerById,
  getCustomers,
  updateCustomer,
  createCity,
  getCities,
  DeviceTokenReset,
  uniqueCode,
  getUsers,
  getParty,
}
