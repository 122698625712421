import { FC, createContext, useContext, useState } from 'react'
import {
  QueryRequestContextProps,
  QueryState,
  WithChildren,
} from '../../../../../../_metronic/helpers'

const initialQueryState: any = {
  page: 1,
  perPage: 20,
  senderId:'',
  receiverId:''
}

const initialQueryRequest: any = {
  state: initialQueryState,
  updateState: () => {},
}

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState
    setState(updatedState)
  }
  
  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }
