import { alertShortCutAction } from "./types"

const initialState = {
    module_name: "",
    status: "",
    time: 2
}

export const shortCutReducer = (state = initialState,{type, module_name, status, time}) => {
    switch(type){
        case alertShortCutAction.SET_SHORT_CUT:
            return {
                ...state, 
                module_name: module_name,
                status: status,
                time: time,
            }
        case alertShortCutAction.REMOVE_SHORT_CUT:
            return {
                ...state, 
                module_name: "",
                status: "",
                time: 2,
            }      
        default: return state;
    }
}