/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs} from '../../../helpers'
import {
  getNotifications,
  updateNotification,
} from 'src/_metronic/layout/components/header/_requests'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { stopReload } from 'src/store/ReloadNotification/actions'

const HeaderNotificationsMenu = ({showRedDot}: any) => {
  const [data, setData] = useState<any>([])
  const dispatch = useDispatch()
  const reloadData = useSelector((state: any) => state.reloadNotification.module_name)
  const fetchData = async () => {
    try {
      await getNotifications()
        .then((data: any) => {
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            setData(data.responseData)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (reloadData == 'reload') {

      fetchData()
      setTimeout(() => {
        dispatch(stopReload())
      }, 3000)
    }
  }, [reloadData])
  const handleNotificationClick = async (uuid: string) => {
    try {
      await updateNotification(uuid)
        .then((data: any) => {
          fetchData()
          showRedDot()
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            fetchData()
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error updating notification:', error)
    }
  }

  return (
    <div
      className='menu menu-sub-dropdown menu-column w-500px w-lg-550px  notification-change-position'
      data-kt-menu='true'
      style={{}}
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        // style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
        style={{backgroundColor: '#062d4a'}}
      >
        <h3 className='text-white text-start fw-bold px-9 mt-6 mb-6'>Notifications</h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'></li>
        </ul>
      </div>

      <div className='tab-content'>
        {/* <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'> */}
        <div className='scroll-y mh-500px my-5 px-5'>
          {data.length === 0 ? (
            <div className='text-center m-0 p-0 mt-12 pt-12 text-muted'>
              No notifications available.
            </div>
          ) : (
            data.map((alert: any, index: any) => (
              <div
                key={`alert${index}`}
                className='d-flex flex-stack py-2'
                onClick={() => handleNotificationClick(alert.uuid)}
              >
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                      {' '}
                      {alert?.entity_type === 1 ? (
                        <KTSVG path={`/media/transaction/approval.svg`} className={`svg-icon-2 `} />
                      ) : alert?.entity_type === 2 ? (
                        <KTSVG path={`/media/transaction/cancel.svg`} className={`svg-icon-2 `} />
                      ) : (
                        <KTSVG path={`/media/transaction/send.svg`} className={`svg-icon-2 `} />
                      )}
                    </span>
                  </div>

                  <div className='mb-0 me-2 text-start'>
                    <a
                      href='#'
                      className={`fs-6 ${
                        alert?.read === 1
                          ? 'text-gray-400'
                          : alert?.entity_type === 1
                          ? 'text-success'
                          : alert?.entity_type === 2
                          ? 'text-danger'
                          : 'text-info-800'
                      } text-hover-primary fw-bolder`}
                    >
                      {alert.message_content}
                    </a>
                  </div>
                </div>
                <span className='badge badge-light fs-8'>{moment(alert.created_at).fromNow()}</span>
              </div>
            ))
          )}

          {/* <div className='card-body border-top pb-8 notifications-list'>
            <div className='row note-unread'>
              <div className='col-md-9 px-5 py-5'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </div>
              <div className='col-md-3 note-time text-center py-5'>
                Today, <span> 4:30PM</span>
              </div>
            </div>

            <div className='row note-unread'>
              <div className='col-md-9 px-5 py-5'>
                But who has any right to find fault with a man who chooses to enjoy a pleasure that
                has no annoying
              </div>
              <div className='col-md-3 note-time text-center py-5'>
                Yesterday, <span>2:00PM</span>
              </div>
            </div>

            <div className='row read'>
              <div className='col-md-9 px-5 py-5'>
                Nor again is there anyone who loves or pursues or desires to obtain pain of itself,
                because it is pain,
              </div>
              <div className='col-md-3 text-center py-5'>
                2d, <span> 12:50PM </span>
                <p> </p>
              </div>
            </div>

            <div className='row read'>
              <div className='col-md-9 px-5 py-5'>
                But I must explain to you how all this mistaken idea of denouncing pleasure and
                praising pain was born and I will give you a
              </div>
              <div className='col-md-3 text-center py-5'>
                1w, <span> 3:30PM </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default HeaderNotificationsMenu
