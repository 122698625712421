import {Suspense, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Outlet, useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {setMessage, removeMessage} from '../store/alertMessage/actions'
import {AuthInit} from './modules/auth'
import {configuration} from '../configuration.js'
import {setCityList, setDropdowmBranchList} from '../store/commonlist/actions'
import axios from 'axios'
import {removeShortCut, setShortCut} from 'src/store/shortCut/actions'
import AddMessage from './pages/AddMessage'


const App = () => {
  const API_URL = configuration.apiUrl
  const CITY_URL = `${API_URL}/cities`
  const USER_URL = `${API_URL}/users`
  const DROPDOWN_BRANCHES_URL = `${API_URL}/branches/getDropDownBranches`
  const alertMessage = useSelector((state: any) => state.alertMessage.message)
  const alertStatus = useSelector((state: any) => state.alertMessage.status)
  const alertTime = useSelector((state: any) => state.alertMessage.time)
  const isLoggedIn = useSelector((state: any) => state.authDetail.isLoggedIn)
  const [isAddMessageModalOpen, setIsAddMessageModalOpen] = useState(false)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const fetchUserPermissions = async (userId:any) => {
    try {
      const { data } = await axios.get(`${USER_URL}/${userId}?sort=name&order=ASC`)
      dispatch(setCityList(data?.responseData))
    } catch (error:any) {
      dispatch(setMessage('error', error.message))
    }
  }

  const getPermission = async () => {
    const userData = localStorage.getItem('objUser')
    if (!userData) return
    const userId = JSON.parse(userData).id
    await fetchUserPermissions(userId)
  }

  useEffect(() => {
    if (localStorage.getItem('authtoken')) {
      getPermission()
    }
  }, [localStorage.getItem('authtoken')])

  const cityList = useSelector((state:any) => state.commonList.cityList)

  const isAdmin = cityList?.roleInfo?.id === 1 || cityList?.roleInfo?.id === 2
  const isMessageCreatePermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.message_is_create === true
  const isPartyCreatePermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.party_is_create === true

  const handleKeyDown = (event:any) => {
    if (!localStorage.getItem('authtoken')) {
      return
    }
    switch (event.keyCode) {
      case 69: // Add Message (Ctrl + Alt + E)
        if (event.ctrlKey && event.altKey) {
          if (isAdmin || isMessageCreatePermission) {
            setIsAddMessageModalOpen(true)
          }
        }
        break
      case 78: // Add Party (Ctrl + Alt + N)
        if (event.ctrlKey && event.altKey) {
          if (isAdmin || isPartyCreatePermission) {
            setIsAddMessageModalOpen(false)
            navigate('/party/list-party')
            dispatch(setShortCut('Add Party', 'partyActive'))
            setTimeout(() => {
              dispatch(removeShortCut())
            }, 3000)
          }
        }
        break
      case 76: // Party Ledger (Ctrl + Alt + L)
        if (event.ctrlKey && event.altKey) {
          navigate('/partyledger/list-partyledger')
        }
        break
      case 65: // General Ledger (Ctrl + Alt + A)
        if (event.ctrlKey && event.altKey) {
          navigate('/report/generalledger/list-generalledger')
        }
        break
      case 77: // Change Password (Ctrl + M)
        if (event.ctrlKey) {
          navigate('/settings/change-psw')
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAdmin, isMessageCreatePermission, isPartyCreatePermission])

  const fetchBranches = async () => {
    try {
      const { data } = await axios.get(`${DROPDOWN_BRANCHES_URL}?sort=branch_name&order=asc`)
      dispatch(
        setDropdowmBranchList(
          data?.responseData?.map((x:any) => ({
            label: x.branch_name,
            value: x.uuid,
            ...x,
          }))
        )
      )
    } catch (error:any) {
      dispatch(setMessage('error', error.message))
    }
  }

  const fetchCities = async () => {
    try {
      const { data } = await axios.get(`${CITY_URL}?sort=name&order=ASC`)
      dispatch(setCityList(data?.responseData))
    } catch (error:any) {
      dispatch(setMessage('error', error.message))
    }
  }

  useEffect(() => {
    if (alertMessage) {
      const message = alertMessage
      const status = alertStatus
      const time = alertTime
      dispatch(removeMessage())
      const toastOptions:any = {
        toastId: message,
        position: 'top-center',
        autoClose: time * 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      }
      if (status === 'success') {
        toast.success(message, toastOptions)
      } else if (status === 'error') {
        toast.error(message, toastOptions)
      }
    }
  }, [alertMessage])

  const closeModal = () => {
    setIsAddMessageModalOpen(false)
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ToastContainer limit={1} />
          <AuthInit>
            <Outlet />
            <MasterInit />
            {localStorage.getItem('authtoken') && (
              <AddMessage isOpen={isAddMessageModalOpen} onClose={closeModal} />
            )}
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
