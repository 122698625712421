// reducer.js

import { userPermissionActionTypes } from "./types";

const initialState = {
    permissions: null
}

export const userPermissionReducer = (state = initialState, action) => {
    switch(action.type){
        case userPermissionActionTypes.SET_USER_PERMISSIONS:
            return {
                ...state, 
                permissions: action.permissions
            }
        case userPermissionActionTypes.CLEAR_USER_PERMISSIONS:
            return {
                ...state, 
                permissions: null
            }
        default: return state;
    }
}
