// import {KTSVG} from '../../../../../../_metronic/helpers'
import { KTSVG } from 'src/_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {AuditsListFilter} from './AuditsListFilter'
// import {FormattedMessage} from 'react-intl'


const AuditsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddServicecenterModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-servicecenter-table-toolbar='base'>
      <AuditsListFilter />

      {/* begin::Export */}
      {/* <a  href='media/misc/sample3.docx' className='btn btn-warning me-3' download>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </a> */}
      {/* end::Export */}

      {/* begin::Add servicecenter */}
      {/* <button type='button' className='btn btn-success' onClick={openAddServicecenterModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' /> */}
        {/* <FormattedMessage id='ADD' /> */} 
        {/* ADD */}
      {/* </button> */}

      {/* end::Add servicecenter */}
    </div>
  )
}

export {AuditsListToolbar}
