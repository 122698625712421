/* eslint-disable jsx-a11y/anchor-is-valid */
import { url } from 'inspector'
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import { toAbsoluteUrl } from 'src/_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 auth-bg' 
    style={{
      backgroundImage: `url('${toAbsoluteUrl(
        '/media/auth/bg4.png'
      )}')`,
      backgroundRepeat:'no-repeat',
      backgroundSize:'100%',
      objectFit:'cover'

    }}
    >
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10 card'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
