// import { KTSVG } from '../../../../../_metronic/helpers'
import { KTSVG } from 'src/_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import {useIntl} from 'react-intl'

const AuditEditModalHeader = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const intl = useIntl()


  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? 
      // <FormattedMessage id='EDIT' />
        'Edit Parties' :  'Add Parties' 
      //  <FormattedMessage id='ADD' /> 
       } 
       {/* <FormattedMessage id='PARTIES' />  */}
        </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-servicecenters-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export { AuditEditModalHeader }
