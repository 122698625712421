import axios, {AxiosResponse} from 'axios'
import { ID, Response } from 'src/_metronic/helpers'
import { configuration } from 'src/configuration'
// import {ID, Response} from '../../../../../_metronic/helpers'
// import {configuration} from '../../../../../configuration'
const API_URL = configuration.apiUrl
const Audit_URL = `${API_URL}/audits`
const USER_URL = `${API_URL}/users/allUser`
const City_URL = `${API_URL}/cities`

const getAudits = (query: string): Promise<any> => {
  return axios.get(`${Audit_URL}?${query}`).then((d: AxiosResponse<any>) => d.data)
}
const getUsers = (query: string): Promise<any> => {
  return axios.get(`${USER_URL}?sort=name&order=ASC`).then((d: AxiosResponse<any>) => d.data)
}
const uniqueCode = (query: string): Promise<any> => {
  return axios.get(`${Audit_URL}/uniqueCode?party_code=${query}`).then((d: AxiosResponse<any>) => d.data)
}
const getCities = (): Promise<any> => {
  return axios.get(`${City_URL}?sort=name&order=ASC`).then((d: AxiosResponse<any>) => d.data)
}
const getAuditById = (id: any): Promise<any> => {
  return axios
    .get(`${Audit_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const createAudit = (data: any): Promise<any> => {
  return axios
    .post(Audit_URL, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const createCity = (data: any): Promise<any> => {
  return axios.post(City_URL, data).then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateAudit = (data: any, id: any): Promise<any> => {
  return axios
    .patch(`${Audit_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteAudit = (id: any): Promise<any> => {
  return axios
    .delete(`${Audit_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const DeviceTokenReset = (id: any): Promise<any> => {
  return axios
    .delete(`${Audit_URL}/deviceTokenReset/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteSelectedAudits = (AuditIds: Array<ID>): Promise<void> => {
  const requests = AuditIds.map((id) => axios.delete(`${Audit_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  createAudit,
  deleteAudit,
  deleteSelectedAudits,
  getAuditById,
  getAudits,
  updateAudit,
  getUsers,
  createCity,
  getCities,
  DeviceTokenReset,
  uniqueCode
}
