import React, {useEffect, useState,useRef} from 'react'
import {useDispatch} from 'react-redux'
import {setMessage} from '../../../../store/alertMessage/actions'
import {changePasswordApi} from '../../../../app/modules/auth/core/_requests'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from 'src/_metronic/layout/core'
import {useFormik} from 'formik'
import * as Yup from 'yup'

const ChangePsw = () => {
  const [email, setEmail] = useState<string | null>(null)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const loginData: any = localStorage.getItem('objUser')
  const currentUser = JSON.parse(loginData)
  useEffect(() => {
    // Change the document title
    document.title = `${currentUser.name} :: Change Password`;
  }, []); 
  useEffect(() => {
    const userData = localStorage.getItem('objUser')
    if (userData) {
      const currentUser = JSON.parse(userData)
      if (currentUser && currentUser.email) {
        setEmail(currentUser.email)
      }
    }
  }, [])

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current?.focus();
    }
  }, []);
  const changePasswordInitialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const editChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required('Old password is required')
      .min(7, 'Password must be at least 7 characters'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(7, 'Password must be at least 7 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character. ex - Abcd@123'
      )
      .notOneOf([Yup.ref('oldPassword')], 'New password cannot be the same as the old password'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword'), null], "Password don't match. Please try again."),
  })
  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: editChangePasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      let sendData = JSON.parse(JSON.stringify(values))
      setSubmitting(true)
      try {
        setLoading(true)
        await changePasswordApi({
          // email,
          oldPassword: formik?.values?.oldPassword,
          newPassword: formik?.values?.newPassword,
        })
          .then((data: any) => {
            if (data?.statusCode === 200 || data?.responseStatus === 200) {
              dispatch(setMessage('success', data?.message ?? data?.responseMessage))
            } else {
              dispatch(
                setMessage(
                  'error',
                  data?.responseData?.msg ? data?.responseData?.msg : data?.responseMessage
                )
              )
            }
            setLoading(false)
            setSubmitting(false)
          })
          .catch((error: any) => {
            if (error?.response?.data?.responseData?.msg) {
              dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
            } else if (error?.response?.data?.responseMessage) {
              dispatch(setMessage('error', error?.response?.data?.responseMessage))
            } else {
              dispatch(setMessage('error', error?.message))
            }
            setLoading(false)
            setSubmitting(false)
          })
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  return (
    <>
      <h2 className='text-center mb-10'>Change Password</h2>
      <div className='card col-md-6 mx-auto p-15'>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='mb-6'>
            <label className='required fs-4 form-label'>Old Password</label>
            <input
              type='password'
              className='form-control'
              placeholder='Enter Old Password'
              ref={inputRef}
              value={formik?.values?.oldPassword}
              onChange={(e) => formik.setFieldValue('oldPassword', e.target.value.trimStart())}
            />
            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.oldPassword as any}</span>
              </div>
            )}
          </div>
          <div className='mb-6'>
            <label className='required fs-4 form-label'>New Password</label>
            <input
              type='password'
              className='form-control'
              placeholder='Enter New Password'
              value={formik?.values?.newPassword}
              onChange={(e) => formik.setFieldValue('newPassword', e.target.value.trimStart())}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.newPassword as any}</span>
              </div>
            )}
          </div>
          <div className='mb-6'>
            <label className='required fs-4 form-label'>Confirm Password</label>
            <input
              type='password'
              className='form-control'
              placeholder='Enter Confirm Password'
              value={formik?.values?.confirmPassword}
              onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value.trimStart())}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.confirmPassword as any}</span>
              </div>
            )}
          </div>
          <div className='mt-6 text-center'>
            <button type='submit' className='btn fs-2' disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChangePsw
