// import {KTCard} from '../../../../_metronic/helpers'
import {KTCard} from 'src/_metronic/helpers'
import {AuditsListHeader} from './components/header/AuditsListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {AuditEditModal} from './customer-edit-modal/AuditEditModal'
import {AuditsTable} from './table/AuditsTable'
import {AuditEditModal} from './audit-edit-modal/AuditEditModal'
import {useSelector} from 'react-redux'

const AuditsList = () => {
  const {itemIdForUpdate} = useListView()
  const cityList = useSelector((state: any) => state.commonList.cityList)
  const isAdmin = cityList?.roleInfo?.id === 1 || cityList?.roleInfo?.id === 2

  const isAuditLogPermission =
    cityList?.roleInfo?.id === 3 && cityList?.userPermissionInfo[0]?.audit_logs === true

  return (
    <>
      <KTCard>
        <AuditsListHeader />
        {isAdmin || isAuditLogPermission ? <AuditsTable /> : ''}
      </KTCard>
      {itemIdForUpdate !== undefined && <AuditEditModal />}
    </>
  )
}

const AuditsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AuditsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AuditsListWrapper}
