import { alertShortCutAction } from "./types";

export const setShortCut = (status, module_name, time = 4) => {
    return {
        type: alertShortCutAction.SET_SHORT_CUT,
        module_name: module_name,
        status: status,
        time: time
    };
}

export const removeShortCut = (status, module_name, time = 4) => {
    return {
        type: alertShortCutAction.REMOVE_SHORT_CUT,
        module_name: module_name,
        status: status,
        time: time
    };
}