import clsx from 'clsx'
import {useDispatch, useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {constants} from '../../../../utils/const'
import {HeaderUserMenu} from '../../../partials'
import HeaderNotificationsMenu from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import {getNotifications} from './_requests'
import {useEffect, useState} from 'react'
import {relative} from 'path'
import Slider from '@mui/material/Slider'
import {Col, Row} from 'react-bootstrap'
import {useLayout} from '../../core'
import {stopReload} from 'src/store/ReloadNotification/actions'

const itemClass = ''
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
const redDotClass = 'red-dot'
const Navbar = ({showSidebar}: any) => {
  const userDetail = useSelector((user: any) => user?.authDetail?.data)
  const loginData: any = localStorage.getItem('objUser')
  const currentUser = JSON.parse(loginData)

  const [data, setData] = useState<any>([])
  const [zoomLevel, setZoomLevel] = useState<any>(100)
  
  
  const fetchData = async () => {
    try {
      await getNotifications()
        .then((data: any) => {
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            setData(data.responseData)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
 

  const redDotBlink = !data?.every((notification: any) => notification?.read === 1)

  // const handleZoomChange = (event: any, newValue: any) => {
  //   setZoomLevel(newValue as number)
  //   document?.body?.style?.zoom = newValue
  // }

  return (
    <div className='app-navbar flex-shrink-0'>
      <Row md={12}>
        <Col className='text-center'>
          <h3 className='logo-color' style={{color: 'white'}}>
            <div className={clsx('app-navbar-item ms-3', itemClass)}>
              <div
                className={clsx('cursor-pointer symbol', userAvatarClass)}
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
              >
                <div className='d-flex align-items-center justify-content-center app-sidebar-menu'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/user.jpg')}
                    className='h-30px small-logo border-radius'
                    style={{borderRadius: '4px'}}
                  />
                  <div className='ms-3 menu-title'>
                    {currentUser?.user_name ? currentUser?.user_name : constants.PROJECT_NAME}
                  </div>
                </div>
                {/* {toggleType}{' '} */}
                {/* {showSidebar ? (
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/user.jpg')}
                    className='h-40px app-sidebar-logo-minimize small-logo'
                  />
                ) : (
                  <div className='ms-8'>
                    {currentUser?.user_name ? currentUser?.user_name : constants.PROJECT_NAME}
                  </div>
                )} */}
              </div>
              <HeaderUserMenu />
            </div>
          </h3>
        </Col>
      </Row>
    </div>
  )
}

export {Navbar}
