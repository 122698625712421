/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {logoutApi} from '../../../../app/modules/auth/core/_requests'
import {setMessage} from '../../../../store/alertMessage/actions'
import {removeAuth} from '../../../../store/authDetail/actions'
import clsx from 'clsx'

import {useEffect, useState} from 'react'
import {relative} from 'path'
import Slider from '@mui/material/Slider'
import {Col, Row} from 'react-bootstrap'
import {getNotifications} from 'src/_metronic/layout/components/header/_requests'
import HeaderNotificationsMenu from './HeaderNotificationsMenu'
import { stopReload } from 'src/store/ReloadNotification/actions'

const itemClass = ''
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
const redDotClass = 'red-dot'
const HeaderUserMenu: FC = () => {
  
  const navigate = useNavigate()

  // const logout = async () => {
  //   try {
  //     await logoutApi()
  //     dispatch(setMessage('success', 'Logout Successfully'))
  //     localStorage.clear()
  //     dispatch(removeAuth())
  //     navigate('/auth/login')
  //   } catch (err) {
  //     dispatch(setMessage('success', 'Logout Successfully'))
  //     localStorage.clear()
  //     dispatch(removeAuth())
  //     navigate('/auth/login')
  //   }
  // }
  const [data, setData] = useState<any>([])
  
  const fetchData = async () => {
    try {
      await getNotifications()
        .then((data: any) => {
          if (data?.statusCode === 200 || data?.responseStatus === 200) {
            setData(data.responseData)
          }
        })
        .catch((error: any) => {
          if (error?.response?.data?.responseData?.msg) {
            console.log(error?.response?.data?.responseData?.msg)
          } else if (error?.response?.data?.responseMessage) {
            console.log(error?.response?.data?.responseMessage)
          } else {
            console.log(error)
          }
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  
  const redDotBlink = !data?.every((notification: any) => notification?.read === 1)

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  return (
    <div
      className='menu menu-sub custom-noti-link menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 '
      data-kt-menu='true'
      style={{maxHeight: '80px', minHeight: '50px', backgroundColor: '#062d4a' ,width :'180px'}}
    >
      <div className='menu-item ps-10 py-2 text-start fw-normal' onClick={toggleFullScreen} style={{fontWeight:'normal' , fontSize:'14px'}}>
       <span className='me-2'> <i className="fa-solid fa-expand fs-5  text-white"></i> </span> Full screen
      </div>
      <div className='menu-item ps-10 py-2 text-start'>
        {/* <div className={clsx('app-navbar-item notif', itemClass)}> */}
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          // className={btnClass}
        >
          {/* <i
                className={`fa fa-bell fa-2x ${btnIconClass}`}
                aria-hidden='true'
                style={{position: 'relative'}}
              > */}
          <p aria-hidden='true' style={{position: 'relative', fontWeight:'normal' , fontSize:'14px'}}>
            <span className='me-2 text-white fw-normal'><i className="fa-solid fa-bell fs-5  text-white"></i></span> Notifications
            {redDotBlink && <span className={redDotClass}></span>}
          </p>
          {/* </i> */}
        </div>
        <HeaderNotificationsMenu showRedDot={fetchData} />
        {/* </div> */}
      </div>
    </div>
  )
}

export {HeaderUserMenu}
