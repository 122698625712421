import {Link} from 'react-router-dom'
import {useLayout} from '../../core'
import clsx from 'clsx'
import {constants} from '../../../../utils/const'
import {Navbar} from '../header/Navbar'
import {Row} from 'react-bootstrap'
import {KTSVG} from 'src/_metronic/helpers'
import { useState } from 'react'

const SidebarLogo = () => {
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  const [showSidebar,setShowSidebar] = useState(false) 

  return (
    <>
      <div className='app-sidebar-logo  px-6 justify-content-start' id='kt_app_sidebar_logo'>
        <Row>
          <Navbar showSidebar={showSidebar}/>
        </Row>
      </div>
      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted open-close-indicater btn-active-color-primary body-bg h-30px w-30px position-absolute start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          style={{zIndex: '99'}}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
          onClick={()=>setShowSidebar(!showSidebar)}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )}
    </>
  )
}

export {SidebarLogo}
