import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {useState} from 'react'
import {configuration} from 'src/configuration'
const API_URL = configuration.apiUrl
const LogoutUser = () => {
  const logout = async () => {
    try {
      let responseData = await axios.get(`${API_URL}/auth/logout`)
    } catch (error) {
      console.error(error)
    }
  }
  //   const navigate = useNavigate();

  // useEffect(() => {
  //   logout()
  // }, [])

  return <div>Logging out...</div>
}

export default LogoutUser
