import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
// import {PageTitle} from '../../../_metronic/layout/core'
// import {AuditsListWrapper} from './audits-list/AuditsList'
import {useIntl} from 'react-intl'
import { PageTitle } from 'src/_metronic/layout/core'
import { AuditsListWrapper } from './audits-list/AuditsList'
import { useEffect } from 'react'
const AuditPage = () => {
  const intl = useIntl()
  const loginData: any = localStorage.getItem('objUser')
  const currentUser = JSON.parse(loginData)
  useEffect(() => {
    // Change the document title
    document.title = `${currentUser.name} :: Audit log`;
  }, []); 
  return (
    <>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='list-audit'
            element={
              <>
                <PageTitle>
                  Audit Log
                  </PageTitle>
                <AuditsListWrapper />
              </>
            }
          />
        </Route>

        <Route index element={<Navigate to='/auditlog' />} />
      </Routes>
    </>
  )
}

export default AuditPage
