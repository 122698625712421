import { combineReducers } from "redux";
import { authDetailReducer } from "./authDetail/reducers";
import { alertMessageReducer } from "./alertMessage/reducers";
import { commonListReducer } from "./commonlist/reducers";
import { shortCutReducer } from "./shortCut/reducers";
import { userPermissionReducer } from "./permission/reducers";
import { reloadNotification} from "./ReloadNotification/reducers"

const reducers = combineReducers({
    authDetail: authDetailReducer,
    alertMessage: alertMessageReducer,
    commonList: commonListReducer,
    shortCut: shortCutReducer,
    userPermission: userPermissionReducer,
    reloadNotification: reloadNotification

})

export default reducers;