/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Stack from '@mui/material/Stack'
import TablePagination from '@mui/material/TablePagination'
import Pagination from '@mui/material/Pagination'
const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const AuditsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {state, updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, perPage: pagination.perPage || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-8 d-flex align-items-center justify-content-center justify-content-md-end'>
        {pagination.total ? (
          <>
            <Stack spacing={2}>
              <Pagination
                count={pagination.totalPageNumber}
                defaultPage={1}
                page={pagination.page}
                showFirstButton
                showLastButton
                onChange={(e: any, p: any) => {
                  updateState({
                    page: p,
                    // , perPage: pagination.perPage || 10
                  })
                }}
              />
            </Stack>
            <TablePagination
              className='pt-9 cust-pagination'
              component='p'
              count={pagination.total}
              page={state.page - 1}
              onPageChange={(e: any) => {
                updateState({page: pagination.page, perPage: e.target.value})
              }}
              rowsPerPage={state.perPage}
              // onRowsPerPageChange={(e: any) => {
              //   updateState({page: pagination.page, perPage: e.target.value})
              // }}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newPerPage = parseInt(event.target.value, 10)
                const totalPages = Math.ceil(pagination.total / newPerPage)
                let newPage = state.page

                // Check if the current page exceeds the new total number of pages
                if (newPage > totalPages) {
                  newPage = totalPages
                }

                updateState({page: newPage, perPage: newPerPage as 10 | 30 | 50 | 100})
              }}
              nextIconButtonProps={{style: {display: 'none'}}}
              backIconButtonProps={{style: {display: 'none'}}}
            />
          </>
        ) : (
          ''
        )}
      </div>
      {/* <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_servicecenters_paginate'>
          <ul className='pagination'>
            {pagination.links
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div> */}
    </div>
  )
}

export {AuditsListPagination}
