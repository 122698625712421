import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {configuration} from '../../../../configuration'

const API_URL = configuration.apiUrl
const Notification_URL = `${API_URL}/notification`


const getNotifications = (): Promise<any> => {
  return axios.get(`${Notification_URL}`).then((d: AxiosResponse<any>) => d.data)
}


const getNotificationById = (id: any): Promise<any> => {
  return axios
    .get(`${Notification_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}


const updateNotification = ( id: any): Promise<any> => {
  return axios
    .patch(`${Notification_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}


export {
 
  getNotificationById,
  getNotifications,
  updateNotification,
  
}
