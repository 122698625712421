import { reloadNotificationAction } from "./types";

export const startReload = (status, module_name, time = 4) => {
    return {
        type: reloadNotificationAction.START_RELOAD,
        module_name: module_name,
        status: status,
        time: time
    };
}

export const stopReload = (status, module_name, time = 4) => {
    return {
        type: reloadNotificationAction.STOP_RELOAD,
        module_name: module_name,
        status: status,
        time: time
    };
}